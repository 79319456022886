import React, { useState } from 'react';
import '@fontsource/poppins';
import '@fontsource/montserrat';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import { styled } from '@mui/system';
import gearoil from "../assets/images/compressedimages/gearoil.jpg"; 
import EVCharger from "../assets/images/compressedimages/EVCharger.jpg"; 
import CarWindowCover from "../assets/images/compressedimages/CarWindowCover.jpg"; 
import CarEngineOil from "../assets/images/compressedimages/CarEngineOil.jpg"; 
import Coolant from "../assets/images/compressedimages/Coolant.jpg";
import MUDFLAPS from "../assets/images/compressedimages/MUDFLAPS.jpg";
import RoofRack from "../assets/images/compressedimages/RoofRack.jpg";
import EngineOilFilter from "../assets/images/compressedimages/EngineOilFilter.jpg";
import ClutchWire from "../assets/images/compressedimages/ClutchWire.jpg";
import CarWiperBlades from "../assets/images/compressedimages/CarWiperBlades.jpg";
import Headlightslamp from "../assets/images/compressedimages/Headlightslamp.jpg";
import CarSideMirrors from "../assets/images/compressedimages/CarSideMirrors.jpg";
import CarServicesGloves from "../assets/images/compressedimages/ClutchWire.jpg";
import CarGPRS from "../assets/images/compressedimages/CarGPRS.jpg";
import CarTent from "../assets/images/compressedimages/CarTent.jpg";
import BreakPad from "../assets/images/compressedimages/BreakPad.jpg";
import BreakOil from "../assets/images/compressedimages/BreakOil.png";
import TailLamp from "../assets/images/compressedimages/TailLamp.jpeg";
import SterringLock from "../assets/images/compressedimages/SterringLock.jpg";
import SparkPlug from "../assets/images/compressedimages/SparkPlug.jpeg";
import ClutchPlate from "../assets/images/compressedimages/ClutchPlate.jpg";
import CarBattery from "../assets/images/compressedimages/CarBattery.jpg";
import aboutusbg from '../assets/images/carthree.jpg';
import emailjs from 'emailjs-com'; 
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  ExploreEVFriendly from '../assets/images/compressedimages/ExploreEVFriendlyProducts.png';
import  PremiumAuto from '../assets/images/PremiumAuto.png';
import  UsedEngines from '../assets/images/Used Engines.jpg';
import  UsedTransmissions from '../assets/images/Used Transmissions.jpeg';
import PhoneIcon from '@mui/icons-material/Phone';



const products = [
  { name: 'Used Engines', image: UsedEngines },
  { name: 'Used Transmissions', image: UsedTransmissions },
  { name: 'Gear Oil', image: gearoil },
  { name: 'EV Charger', image: EVCharger },
  { name: 'Car Window Cover', image: CarWindowCover },
  { name: 'Car Engine Oil', image: CarEngineOil },
  { name: 'Car Tent', image: CarTent },
  { name: 'Coolant', image: Coolant },
  { name: 'Mud Flaps', image: MUDFLAPS },
  { name: 'Roof Rack', image: RoofRack },
  { name: 'Car GPRS', image: CarGPRS },
  { name: 'Engine Oil Filter', image: EngineOilFilter },
  { name: 'Clutch Wire', image: ClutchWire },
  { name: 'Clutch Plate', image: ClutchPlate },
  { name: 'Car Wiper Blades', image: CarWiperBlades },
  { name: 'Headlights Lamp', image: Headlightslamp },
  { name: 'Car Side Mirrors', image: CarSideMirrors },
  { name: 'Car Services Gloves', image: CarServicesGloves },
  { name: 'Car Battery', image: CarBattery },
  { name: 'Brake Pad', image: BreakPad },
  { name: 'Brake Oil', image: BreakOil },
  { name: 'Tail Lamp', image: TailLamp },
  { name: 'Steering Lock', image: SterringLock },
  { name: 'Spark Plug', image: SparkPlug },
];

const StyledCard = styled(Card)({
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
  },
});
const ProductsPage = ({ showBanner }) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
   
  });

  const [errors, setErrors] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({ name: '', email: '', phone: ''}); // Reset form on close
    setErrors({}); // Reset errors on close
  };
 

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' }); // Clear error for the field being edited
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.phone) newErrors.phone = 'Phone number is required';


    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors); 
      return; 
    }

    emailjs
      .send(
     'service_xs37ipj', 'template_ph7df3a', 
        formData,
        'TUhvHgQ0Bp4osZuvg' 
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          toast.success('Email sent successfully!', {
            position: 'top-right',
          });
        },
        (error) => {
          console.log('FAILED...', error);
          toast.error('Failed to send email. Please try again later.', {
            position: 'top-right', 
          });
        }
      );
  };

  return (
    <div
      className="product"
      style={{
        backgroundColor: 'rgba(237, 242, 250, 0.62)',
        padding: '60px 0px',
      }}
    >
    {showBanner && (
      <div
   
        className="top-banner"
        style={{
          backgroundImage: `url(${aboutusbg})`,
          padding: '100px 0px',
          textAlign: 'center',
          fontSize: '18px',
          marginBottom: '40px',
          borderBottom: '2px solid #ccc',
          position: 'relative',
        }}
      >
        <Typography variant="h2" align="center" gutterBottom 
          sx={{
            fontWeight: 'bold',
            color: '#fff', 
            letterSpacing: 0.3, 
            position: 'relative',
            fontSize: '35px',
            fontFamily: '"Poppins", "Arial", sans-serif', 
            textTransform: 'uppercase', 
            textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', 
          }}>
          Our Products
        </Typography>

        <Typography variant="h5" align="center" gutterBottom 
          sx={{
            position: 'relative',
            fontWeight: 'bold',
            color: '#fff', 
            fontFamily: '"Montserrat", "Arial", sans-serif', 
            fontSize: '18px',
            textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', 
          }}>
          Your trusted partner for premium car accessories
        </Typography>

        <style jsx>{`
          .top-banner::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 0;
          }
        `}</style>
      </div>
      )}

      <Container>
        <Box>
          <Typography variant="h2" align="center" gutterBottom 
            sx={{
              fontWeight: 'bold',
              color: '#d32f2f', 
              letterSpacing: 0.3, 
              fontSize: '35px',
              fontFamily: '"Poppins", "Arial", sans-serif', 
              textTransform: 'uppercase', 
              textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', 
            }}>
            Our Products
          </Typography>

          <Typography
            variant="h5" 
            align="center"
            gutterBottom
            sx={{
              fontWeight: 'bold',
              color: '#242424', 
              fontFamily: '"Montserrat", "Arial", sans-serif', 
              textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', 
              marginBottom: '35px',
              fontSize: '18px',
            }}
          >
            Our Range of Products
          </Typography>

          <Grid container spacing={4}>
            {products.map((product, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <StyledCard>
                  <CardMedia
                    component="img"
                    height="180"
                    image={product.image}
                    alt={product.name}
                    loading="lazy"
                    onClick={handleClickOpen} // Open dialog when image is clicked
                    sx={{ cursor: 'pointer' }} 
                  />
                  <CardContent>
                    <Typography variant="h6" align="center" sx={{
                        color: '#242424', 
                        fontFamily: '"Montserrat", "Arial", sans-serif',
                        fontSize: '16px',
                      }}>
                      {product.name}
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        </Box>

        

        <Dialog  open={open} onClose={handleClose}>
           <div className='moDal-Popup'>
           <DialogTitle
              className="modal-poPup"
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' }, 
                justifyContent: 'flex-start',
                alignItems: 'center',
                fontWeight: '600',
                color: '#fff',
                letterSpacing: 0.3,
                gap: { xs: '10px', sm: '20px' }, 
                position: 'relative',
                fontSize: '14px',
                paddingBottom: '5px',
                fontFamily: '"Poppins", "Arial", sans-serif',
                textTransform: 'capitalize',
                textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                <Grid item xs={12} sm="auto" textAlign="left">
                  <span>Enquire Now</span>
                </Grid>
                <Grid item xs={12} sm="auto" textAlign="left">
                  <a href="tel:+18889275557" style={{ textDecoration: 'none', width: '100%' }}>
                    <Button
                      variant="contained"
                      startIcon={<PhoneIcon />}
                      sx={{
                        backgroundColor: '#000',
                        color: '#fff',
                        fontSize: '14px',
                        fontFamily: '"Montserrat", "Arial", sans-serif',
                        padding: '8px 12px',
                        borderRadius: '8px',
                        fontWeight: '600',
                        textTransform: 'none',
                        width: { xs: 'auto', sm: 'auto' }, 
                        '&:hover': {
                          backgroundColor: '#444',
                        },
                      }}
                    >
                      Call Now
                    </Button>
                  </a>
                </Grid>
              </Grid>
            </DialogTitle>



          <DialogContent>
          <TextField
              autoFocus
              margin="dense"
              label="Name"
              type="text"
              fullWidth
              variant="outlined"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={!!errors.name} // Show error state
              helperText={errors.name} // Show error message
              InputLabelProps={{
                sx: {
                  '&.Mui-focused': {
                    color: '#000',
                  },
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'gray',
                  },
               
                  '&.Mui-focused fieldset': {
                    borderColor: '#000',
                  },
                },
              }}
            />
            <TextField
              margin="dense"
              label="Phone No."
              type="text"
              fullWidth
              variant="outlined"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              error={!!errors.phone} // Show error state
              helperText={errors.phone} // Show error message
              InputLabelProps={{
                sx: {
                  '&.Mui-focused': {
                    color: '#000',
                  },
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'gray',
                  },
               
                  '&.Mui-focused fieldset': {
                    borderColor: '#000',
                  },
                },
              }}
            />

        <TextField
              margin="dense"
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email} // Show error state
              helperText={errors.email} // Show error message
              InputLabelProps={{
                sx: {
                  '&.Mui-focused': {
                    color: '#000',
                  },
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'gray',
                  },
                  
                  '&.Mui-focused fieldset': {
                    borderColor: '#000',
                  },
                },
              }}
            />

          <TextField
              margin="dense"
              label="Enter Your Requirement"
              type="text"
              fullWidth
              variant="outlined"
              name="requirement"
              value={formData.requirement}
              onChange={handleChange}
              error={!!errors.requirement} // Show error state
              helperText={errors.requirement} // Show error message
              InputLabelProps={{
                sx: {
                  '&.Mui-focused': {
                    color: '#000',
                  },
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'gray',
                  },
                
                  '&.Mui-focused fieldset': {
                    borderColor: '#000',
                  },
                },
              }}
            />

      </DialogContent>
          <DialogActions sx={{
            padding: '0px 20px 20px 0px',
          }}>
         <Button
          onClick={handleClose}
          color="primary"
          sx={{
            backgroundColor: '#000',
            color: '#fff',
            fontSize: '16px',
            fontFamily: '"Montserrat", "Arial", sans-serif',
            padding: '10px 20px',
            borderRadius: '8px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#444',
            },
          }}
        >
          Cancel
        </Button>

            <Button onClick={handleSubmit} color="primary" variant="contained" sx={{
              backgroundColor: '#d32f2f',
              color: '#fff',
              fontSize: '16px',
              fontFamily: '"Montserrat", "Arial", sans-serif',
              padding: '10px 20px',
              borderRadius: '8px',
              textTransform: 'none',
              '&:hover': {
              backgroundColor: '#d32f2fc9',
            },
            }}>
              Submit
            </Button>
          </DialogActions>
           </div>
        </Dialog>
        <ToastContainer />
 
        <Box sx={{ backgroundColor: '#f7fafc', padding: '16px' }}>
        <Grid container spacing={4} mt={4}>
          <Grid item xs={12} md={6}>
            <Card sx={{ position: 'relative', borderRadius: '50px 5px 50px 5px', overflow: 'hidden' }}>
              <CardMedia
                component="img"
                image={ExploreEVFriendly}
                height="400"
                alt="Electric vehicles in a factory setting"
                sx={{ borderRadius: '50px 5px 50px 5px', objectFit: 'cover' }}
              />
              <CardContent
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  padding: '16px',
                }}
              >
                <Typography variant="h6" sx={{
                    fontWeight: 'bold',
                    color: '#fff',
                    letterSpacing: 0.3,
                    position: 'relative',
                    fontSize: '18px',
                    paddingBottom: '5px',
                    fontFamily: '"Poppins", "Arial", sans-serif',
                    textTransform: 'capitalize',
                }}>
                  Explore EV Friendly Products
                </Typography>
                <Typography variant="body1" component="div" color="white" mb={1}>
                  Supporting the electric revolution.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickOpen}
                    sx={{
                      backgroundColor: '#d32f2f',
                      color: '#fff',
                      fontSize: '16px',
                      fontFamily: '"Montserrat", "Arial", sans-serif',
                      padding: '10px 20px',
                      borderRadius: '8px',
                      width: '50%',
                      marginTop: '15px',
                      textTransform: 'none',
                      '&:hover': {
                        backgroundColor: '#d32f2fc9',
                      },
                    }}
                  >
                  Enquire Now
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ position: 'relative', borderRadius: '50px 5px 50px 5px', overflow: 'hidden' }}>
              <CardMedia
                component="img"
                height="400"
                image={PremiumAuto}
                alt="Auto tools for mechanics"
                sx={{ borderRadius: '50px 5px 50px 5px', objectFit: 'cover' }}
              />
              <CardContent
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  padding: '16px',
                }}
              >
                <Typography variant="h6" component="div" sx={{
                    fontWeight: 'bold',
                    color: '#fff',
                    letterSpacing: 0.3,
                    position: 'relative',
                    fontSize: '18px',
                    paddingBottom: '5px',
                    fontFamily: '"Poppins", "Arial", sans-serif',
                    textTransform: 'capitalize',
                }}>
                  Premium Auto Tools & Accessories
                </Typography>
                <Typography variant="body1" component="div" color="white" mb={1}>
                  Quality tools for your vehicle's care.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickOpen} 
                  sx={{
                    backgroundColor: '#d32f2f',
                    color: '#fff',
                    fontSize: '16px',
                    fontFamily: '"Montserrat", "Arial", sans-serif',
                    padding: '10px 20px',
                    borderRadius: '8px',
                    width: '50%',
                    marginTop: '15px',
                    textTransform: 'none',
                    '&:hover': {
                    backgroundColor: '#d32f2fc9',
                    },
                  }}
                >
                  Enquire Now
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      </Container>
    </div>
  );
};

export default ProductsPage;
