

// import React from 'react';
// import { Container, Grid, Typography, TextField, Button, Paper, Link } from '@mui/material';
// import { styled } from '@mui/system';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowRight, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
// import { faFacebookF, faTwitter, faWhatsapp, faPinterest } from '@fortawesome/free-brands-svg-icons';
// import { Box } from '@mui/material';
// import LocationOnIcon from '@mui/icons-material/LocationOn';

// const FooterContainer = styled('footer')(({ theme }) => ({
//     background: 'linear-gradient(to right, #000, #111)',
//     color: '#fff',
//     padding: '60px 0px',
//     borderRadius: '125px 0px 0px 0px',
// }));

// const NewsletterButton = styled(Button)(({ theme }) => ({
//     backgroundColor: theme.palette.primary.main,
//     '&:hover': {
//         backgroundColor: theme.palette.primary.dark,
//     },
// }));

// const handleLocationClick = () => {
//     window.open('https://www.google.com/maps/place/8316+Tenth+Line+N,+Norval,+ON+L0P+1K0,+Canada'); // Opens Google Maps
//   };

// const Footer = () => {
//     return (
//         <FooterContainer>
//             <Container>
//                 <Grid container spacing={4}>
//                     <Grid item xs={12} md={3}>
//                         <Typography variant="h5" gutterBottom>
//                             Home
//                         </Typography>
//                         <Typography variant="body1" sx={{
//                                 color: '#fff',
//                                 fontFamily: '"Montserrat", "Arial", sans-serif',
//                                 fontSize: '14px',
//                                 lineHeight: '36px',
//                                 paddingTop: '8px',
//                             }}>
//                         Quality Used Engines and Auto Parts, Delivered Across Canada and the US. Find top-quality engines, accessories, and vehicle parts at competitive prices. Fast, reliable shipping to keep you on the road.
//                         </Typography>
//                     </Grid>


//                     <Grid item xs={12} md={3}>
//                         <Typography variant="h6" gutterBottom>
//                             Quick Links
//                         </Typography>
//                         <ul style={{ listStyleType: 'none', padding: 0 }}>
//                             <li><Link href="/" color="inherit" underline="hover" sx={{ color: '#fff', fontFamily: '"Montserrat", "Arial", sans-serif', fontSize: '14px', lineHeight: '36px', textTransform: 'capitalize' }}>Home</Link></li>
//                             <li><Link href="/about" color="inherit" underline="hover" sx={{ color: '#fff', fontFamily: '"Montserrat", "Arial", sans-serif', fontSize: '14px', lineHeight: '36px', textTransform: 'capitalize' }}>About Us</Link></li>
//                             <li><Link href="/products" color="inherit" underline="hover" sx={{ color: '#fff', fontFamily: '"Montserrat", "Arial", sans-serif', fontSize: '14px', lineHeight: '36px', textTransform: 'capitalize' }}>Products</Link></li>
//                             <li><Link href="/contact" color="inherit" underline="hover" sx={{ color: '#fff', fontFamily: '"Montserrat", "Arial", sans-serif', fontSize: '14px', lineHeight: '36px', textTransform: 'capitalize' }}>Contact Us</Link></li>
//                             <li><Link href="/OnlineStore" color="inherit" underline="hover" sx={{ color: '#fff', fontFamily: '"Montserrat", "Arial", sans-serif', fontSize: '14px', lineHeight: '36px', textTransform: 'capitalize' }}>Online Store</Link></li>
//                             <li><Link href="/privacy-policy" color="inherit" underline="hover" sx={{ color: '#fff', fontFamily: '"Montserrat", "Arial", sans-serif', fontSize: '14px', lineHeight: '36px', textTransform: 'capitalize' }}>Privacy Policy</Link></li>
//                             <li><Link href="/terms-and-conditions" color="inherit" underline="hover" sx={{ color: '#fff', fontFamily: '"Montserrat", "Arial", sans-serif', fontSize: '14px', lineHeight: '36px', textTransform: 'capitalize' }}>Terms and Conditions</Link></li>
//                         </ul>
//                     </Grid>

                    
//                     <Grid item xs={12} md={3}>
//                         <Typography variant="h5" gutterBottom>
//                             Contact Info
//                         </Typography>
//                         <Typography variant="body1">
//                             <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '8px', color: '#d32f2f' }} />
//                             <Link href="mailto:contact@yndautoparts.com" color="primary" sx={{
//                                 color: '#fff',
//                                 fontFamily: '"Montserrat", "Arial", sans-serif',
//                                 fontSize: '14px',
//                                 lineHeight: '36px',
//                                 textTransform: 'capitalize',
//                                 }}>
//                                 contact@yndautoparts.com
//                             </Link>
//                         </Typography>
//                         <Typography variant="body1" sx={{
//                                 marginTop: '12px',
//                                 color: '#fff',
//                                 fontSize: '14px',
//                                 display: 'flex',
//                                 alignItems: 'center'
//                             }}>
//                             <FontAwesomeIcon icon={faPhone} style={{ marginRight: '8px', color: '#d32f2f' }} />
//                             <Link href="tel:+17255291107" sx={{ color: 'inherit', textDecoration: 'none' }}>
//                                 +1 437 739 0111
//                             </Link>
//                         </Typography>
                        
//                         <Typography
//                         variant="body1"
//                         sx={{
//                             marginTop: '12px',
//                             color: '#fff',
//                             fontSize: '14px',
//                             display: 'flex',
//                             transform: 'translateX(-4px)',
//                             alignItems: 'center',
//                         }}
//                     >
//                         <LocationOnIcon
//                             sx={{ color: '#d32f2f', mr: 0.5 }}
//                             onClick={handleLocationClick}
//                         />
//                         8316 Tenth Line N, Norval, ON L0P 1K0
//                     </Typography>

//                     </Grid>

//                     <Grid item xs={12} md={3}>
//                         <Typography variant="h6" gutterBottom>
//                             Newsletter
//                         </Typography>
//                         <Grid container spacing={1}>
//                             <Grid item xs={8}>
//                                 <TextField
//                                     fullWidth
//                                     variant="outlined"
//                                     placeholder="Enter your email id"
//                                     InputProps={{
//                                         style: {
//                                             background: '#fff',
//                                             borderRadius: '5px',
//                                             color: '#000',
//                                             fontSize: '14px', 
//                                         },
//                                     }}
//                                 />
//                             </Grid>
//                             <Grid item xs={4}>
//                                 <NewsletterButton variant="contained" fullWidth style={{ padding: '19.5px 14px', background: '#d32f2f' }}>
//                                     <FontAwesomeIcon icon={faArrowRight} />
//                                 </NewsletterButton>
//                             </Grid>
//                         </Grid>
//                         <div style={{ display: 'flex', marginTop: '16px', gap: '20px' }}>
//                             <Link href="#" color="inherit" style={{ backgroundColor: '#edf2fa9e', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                                 <FontAwesomeIcon icon={faFacebookF} style={{ color: 'white' }} />
//                             </Link>
//                             <Link href="#" color="inherit" style={{ backgroundColor: '#edf2fa9e', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                                 <FontAwesomeIcon icon={faTwitter} />
//                             </Link>
//                             <Link href="#" color="inherit" style={{ backgroundColor: '#edf2fa9e', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                                 <FontAwesomeIcon icon={faWhatsapp} />
//                             </Link>
//                             <Link href="#" color="inherit" style={{ backgroundColor: '#edf2fa9e', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                                 <FontAwesomeIcon icon={faPinterest} />
//                             </Link>
//                         </div>
//                     </Grid>
//                 </Grid>
//                 <div style={{ borderTop: '1px solid rgba(255, 255, 255, 0.5)', marginTop: '24px', paddingTop: '24px', textAlign: 'center' }}>
//                     <Typography variant="body2">
//                         © yndautoparts,(16409849 CANADA INC) All Rights Reserved. Designed By{" "}
//                         <a href="https://www.softflixitsolutions.com/" target="_blank" rel="noopener noreferrer" style={{
//                             color: '#ccc',
//                             textDecoration: 'underline',
//                             fontSize: '14px',
//                             transition: 'color 0.3s ease'
//                         }}
//                         onMouseEnter={(e) => e.currentTarget.style.color = '#d32f2f'} 
//                         onMouseLeave={(e) => e.currentTarget.style.color = '#d32f2fb3'}>SoftflixItSolutions</a>
//                     </Typography>
//                 </div>
//             </Container>
//         </FooterContainer>
//     );
// };

// export default Footer;



import React from 'react';
import { Container, Grid, Typography, TextField, Button } from '@mui/material';
import { styled } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faWhatsapp, faPinterest } from '@fortawesome/free-brands-svg-icons';
import { Link as RouterLink } from 'react-router-dom'; // Import RouterLink
import LocationOnIcon from '@mui/icons-material/LocationOn';

const FooterContainer = styled('footer')(({ theme }) => ({
    background: 'linear-gradient(to right, #000, #111)',
    color: '#fff',
    padding: '60px 0px',
    borderRadius: '125px 0px 0px 0px',
}));

const NewsletterButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
}));

const handleLocationClick = () => {
    window.open('https://www.google.com/maps/place/8316+Tenth+Line+N,+Norval,+ON+L0P+1K0,+Canada'); // Opens Google Maps
};

const Footer = () => {
    return (
        <FooterContainer>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>
                        <Typography variant="h5" gutterBottom>
                            Home
                        </Typography>
                        <Typography variant="body1" sx={{
                                color: '#fff',
                                fontFamily: '"Montserrat", "Arial", sans-serif',
                                fontSize: '14px',
                                lineHeight: '36px',
                                paddingTop: '8px',
                            }}>
                        Quality Used Engines and Auto Parts, Delivered Across Canada and the US. Find top-quality engines, accessories, and vehicle parts at competitive prices. Fast, reliable shipping to keep you on the road.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" gutterBottom>
                            Quick Links
                        </Typography>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            <li><RouterLink to="/" style={{ color: '#fff', textDecoration: 'none', fontFamily: '"Montserrat", "Arial", sans-serif', fontSize: '14px', lineHeight: '36px', textTransform: 'capitalize' }}>Home</RouterLink></li>
                            <li><RouterLink to="/about" style={{ color: '#fff', textDecoration: 'none', fontFamily: '"Montserrat", "Arial", sans-serif', fontSize: '14px', lineHeight: '36px', textTransform: 'capitalize' }}>About Us</RouterLink></li>
                            <li><RouterLink to="/products" style={{ color: '#fff', textDecoration: 'none', fontFamily: '"Montserrat", "Arial", sans-serif', fontSize: '14px', lineHeight: '36px', textTransform: 'capitalize' }}>Products</RouterLink></li>
                            <li><RouterLink to="/contact" style={{ color: '#fff', textDecoration: 'none', fontFamily: '"Montserrat", "Arial", sans-serif', fontSize: '14px', lineHeight: '36px', textTransform: 'capitalize' }}>Contact Us</RouterLink></li>
                            <li><RouterLink to="/OnlineStore" style={{ color: '#fff', textDecoration: 'none', fontFamily: '"Montserrat", "Arial", sans-serif', fontSize: '14px', lineHeight: '36px', textTransform: 'capitalize' }}>Online Store</RouterLink></li>
                            <li><RouterLink to="/privacy-policy" style={{ color: '#fff', textDecoration: 'none', fontFamily: '"Montserrat", "Arial", sans-serif', fontSize: '14px', lineHeight: '36px', textTransform: 'capitalize' }}>Privacy Policy</RouterLink></li>
                            <li><RouterLink to="/terms-and-conditions" style={{ color: '#fff', textDecoration: 'none', fontFamily: '"Montserrat", "Arial", sans-serif', fontSize: '14px', lineHeight: '36px', textTransform: 'capitalize' }}>Terms and Conditions</RouterLink></li>
                        </ul>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Typography variant="h5" gutterBottom>
                            Contact Info
                        </Typography>
                        <Typography variant="body1">
                            <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '8px', color: '#d32f2f' }} />
                            <a href="mailto:contact@yndautoparts.com" style={{ color: '#fff', textDecoration: 'none' }}>
                                contact@yndautoparts.com
                            </a>
                        </Typography>
                        <Typography
  variant="body1"
  sx={{
    marginTop: '12px',
    color: '#fff',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px', // Adds space between the phone numbers
  }}
>
  <span style={{ display: 'flex', alignItems: 'center' }}>
    <FontAwesomeIcon icon={faPhone} style={{ marginRight: '8px', color: '#d32f2f' }} />
    <a href="tel:+14377390111" style={{ color: 'inherit', textDecoration: 'none' }}>
      +1 437 739 0111
    </a>
  </span>
  <span style={{ display: 'flex', alignItems: 'center' }}>
    <FontAwesomeIcon icon={faPhone} style={{ marginRight: '8px', color: '#d32f2f' }} />
    <a href="tel:+18889275557" style={{ color: 'inherit', textDecoration: 'none' }}>
      +1 888 927 5557
    </a>
  </span>
</Typography>

                        
                        <Typography
                        variant="body1"
                        sx={{
                            marginTop: '12px',
                            color: '#fff',
                            fontSize: '14px',
                            display: 'flex',
                            transform: 'translateX(-4px)',
                            alignItems: 'center',
                        }}
                    >
                        <LocationOnIcon
                            sx={{ color: '#d32f2f', mr: 0.5 }}
                            onClick={handleLocationClick}
                        />
                        8316 Tenth Line N, Norval, ON L0P 1K0
                    </Typography>

                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" gutterBottom>
                            Newsletter
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Enter your email id"
                                    InputProps={{
                                        style: {
                                            background: '#fff',
                                            borderRadius: '5px',
                                            color: '#000',
                                            fontSize: '14px', 
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <NewsletterButton variant="contained" fullWidth style={{ padding: '19.5px 14px', background: '#d32f2f' }}>
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </NewsletterButton>
                            </Grid>
                        </Grid>
                        <div style={{ display: 'flex', marginTop: '16px', gap: '20px' }}>
                            <a href="#" style={{ backgroundColor: '#edf2fa9e', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FontAwesomeIcon icon={faFacebookF} style={{ color: 'white' }} />
                            </a>
                            <a href="#" style={{ backgroundColor: '#edf2fa9e', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FontAwesomeIcon icon={faTwitter} style={{ color: 'white' }}/>
                            </a>
                            <a href="#" style={{ backgroundColor: '#edf2fa9e', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FontAwesomeIcon icon={faWhatsapp} style={{ color: 'white' }}/>
                            </a>
                            <a href="#" style={{ backgroundColor: '#edf2fa9e', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FontAwesomeIcon icon={faPinterest} style={{ color: 'white' }}/>
                            </a>
                        </div>
                    </Grid>
                </Grid>
                <div style={{ borderTop: '1px solid rgba(255, 255, 255, 0.5)', marginTop: '24px', paddingTop: '32px', textAlign: 'center' }}>
                    <Typography variant="body2">
    
                        © yndautoparts, All Right Reserved Designed By {""}
                        <a href="https://www.softflixitsolutions.com/" style={{ color: '#edf2fa9e' }} target="_blank" rel="noopener noreferrer">SoftflixItSolutions</a>
                    </Typography>
                </div>
            </Container>
        </FooterContainer>
    );
};
    
export default Footer;

